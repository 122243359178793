import type {
  Challenge,
  ChallengeResponses,
  User,
  UserChallenge,
  UserInformation,
} from "@/interfaces/Auth";
import type { RemovableRef } from "@vueuse/core";

import router from "@/router";

import { autologin, login, validateLogin } from "@/services/auth.services";
import { defineStore } from "pinia";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { useProductStore } from "./productStore";
import type { ProductDetail } from "@/interfaces/product.interface";

interface AuthState {
  currentStep: number;
  userChallenge: UserChallenge;
  token: RemovableRef<string | null>;
  user: RemovableRef<User | null>;
  showMessage: boolean;
  showCloseSesion: boolean;
  error: boolean;
}

const handleError = (e: any) => {
  throw e;
};

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    currentStep: 0,
    userChallenge: {} as UserChallenge,
    token: useStorage("token", null),
    user: useStorage("user", null, localStorage, { serializer: StorageSerializers.object }),
    showMessage: false,
    showCloseSesion: true,
    error: false,
  }),

  getters: {
    answers(state): ChallengeResponses[] {
      return state.userChallenge.challenge || [];
    },
    organizationClientId: () => {
      return import.meta.env.VITE_AUTOLOGIN_ORG_ID;
    },
  },
  actions: {
    async sendChallenge(challenge: Challenge): Promise<void> {
      try {
        const response = await login(challenge);
        this.userChallenge = response.data;
        this.currentStep++;
      } catch (error) {
        handleError(error);
      }
    },

    async validateChallenge(user: UserInformation): Promise<void> {
      try {
        const response = await validateLogin(user);
        this.user = response.data;
        this.token = response.data.access_token;
        localStorage.removeItem("sesionExpired");
        router.push({ name: "Products" });
      } catch (error) {
        handleError(error);
      }
    },

    async automaticLogin(search: string): Promise<void> {
      try {
        const urlParams = new URLSearchParams(search);
        if (urlParams.has("key")) {
          this.showCloseSesion = false;
          const keyValue = urlParams.get("key");
          let data = null

          try {
            const { data: userInfo } = await autologin({
              organization_id: import.meta.env.VITE_AUTOLOGIN_ORG_ID,
              key: keyValue,
            });

            data = userInfo
          } catch (error) {
            throw error
          }

          this.user = data;
          this.token = data.access_token;
          localStorage.removeItem("sesionExpired");
          const hasActiveRequest = data.has_active_request;

          const productStore = useProductStore();

          const productsWithoutPolicies = data.product.map((product) => {
            const { polizas, ...rest } = product;
            return rest;
          });

          productStore.products = productsWithoutPolicies;

          const currentProduct = data.product.find((product) =>
            product.polizas.some((policy) => policy.id === data.poliza_id)
          );

          const { polizas, ...product } = currentProduct ?? {};
          productStore.setCurrentProduct(product);
          productStore.setPolicies(polizas);

          if (hasActiveRequest) {
            router.push({ name: "Requests" });
          } else {
            if (data?.poliza_id) {
              router.push({
                name: "RequestsCreate",
                query: { policy_id: data.poliza_id },
              });
            } else {
              router.push({ name: "Products" });
            }
          }

          this.showMessage = true;
        }
      } catch (error: any) {
        if (error.status === 401) this.error = true;
        router.push({ name: "State" });
        handleError(error);
      }
    },
  },
});
