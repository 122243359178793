import { defineStore } from "pinia";
import { StorageSerializers, useStorage, type RemovableRef } from "@vueuse/core";
import type { Policy } from "../interfaces/product.interface";

import type {
  Product,
  ProductDetail,
  Request,
  RequestDetail,
  Insured,
} from "@/interfaces/product.interface";
import {
  fetchProductByID,
  fetchProducts,
  fetchRequestDetail,
  fetchRequests,
} from "@/services/product.services";

interface ProductStore {
  policies: RemovableRef<Policy[] | null>;
  products: RemovableRef<Product[] | null>;
  currentPolicy: Policy | null;
  currentProduct: RemovableRef<Product | null>;
}

const handleError = (error: unknown) => {
  throw error;
};

export const useProductStore = defineStore("product", {
  state: (): ProductStore => ({
    policies: useStorage("policies", null, localStorage, { serializer: StorageSerializers.object }),
    products: useStorage("products", null, localStorage, { serializer: StorageSerializers.object }),
    currentPolicy: null,
    currentProduct: useStorage("currentProduct", null, localStorage, { serializer: StorageSerializers.object }),
  }),

  getters: {
    insuredInfo(state): Insured {
      return state.currentPolicy?.asegurado ?? null;
    },
  },

  actions: {
    setPolicies(policies: Policy[]): void {
      this.policies = policies;
    },

    setCurrentPolicy(policy: Policy | null): void {
      this.currentPolicy = policy;
    },

    setCurrentProduct(product: Product | any): void {
      this.currentProduct = product;
    },

    async loadProducts(): Promise<void> {
      try {
        const { data } = await fetchProducts();
        this.products = data;
      } catch (error) {
        handleError(error);
      }
    },

    async loadProduct(id: string): Promise<ProductDetail> {
      const { data } = await fetchProductByID(id);
      this.setPolicies(data.polizas);
      return data;
    },

    async loadRequests(): Promise<Request[]> {
      const { data } = await fetchRequests();
      return data;
    },

    async loadRequestDetail(id: string | number): Promise<RequestDetail> {
      const { data } = await fetchRequestDetail(id);
      return data;
    },
  },
});
