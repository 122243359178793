<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { useField } from "vee-validate";

import type { Policy } from "@/interfaces/product.interface";
import { useProductStore } from "@/stores/productStore";
import { useRequestStore } from "@/stores/requestStore";
import CustomButtonGroup from "../CustomButtonGroup.vue";
import { useAutocompleteFields } from "@/composables/autocompleteFields";

import TextInput from "../TextInput.vue";
import { useRoute } from "vue-router";

const productStore = useProductStore();
const reqStore = useRequestStore();
const route = useRoute();
const { autocomplete } = useAutocompleteFields();

const activePolicy = ref<Policy | null>(null);

const { value: policyId, errorMessage: policyIdError } =
  useField<number>("id_poliza");


watch(activePolicy, async (newValue) => {
  if (newValue) {
    productStore.setCurrentPolicy(newValue);
    policyId.value = newValue.id;
    policyId.value && (await reqStore.requestPreload(policyId.value));
  }
}, { deep: true });

watch(
  () => productStore?.insuredInfo,
  (newValue) => {
    if (newValue == null) return

    autocomplete(newValue);
  },
  { deep: true }
);

watch(
  () => route.query?.policy_id,
  (policyNumber) => {
    if (policyNumber) {
      if (productStore.policies?.length === 1) {
        activePolicy.value = productStore.policies[0];
      } else {
        const findPolicy = productStore.policies?.find(
          (policy) => {
            return policy.id === Number(policyNumber)
          }
        );

        if (findPolicy) {
          activePolicy.value = findPolicy;
        }
      }
    }
  }, { immediate: true },
);
</script>

<template>
  <Transition
    enter-active-class="animate__animated animate__fadeIn animate__delay-1s"
    leave-active-class="animate__animated animate__fadeOut animate__fast"
    mode="out-in"
    appear
  >
    <div class="flex flex-col gap-y-11 mb-5 md:mb-11">
      <div>
        <h1 class="text-sm mb-3 title md:text-lg 2xl:text-xl">Póliza</h1>
        <div
          class="border-section py-4 2xl:py-9 px-4 md:px-6 2xl:px-12 grid grid-cols-1 md:grid-cols-3 gap-x-9"
        >
          <CustomButtonGroup
            :options="productStore.policies ?? []"
            v-model="activePolicy"
            item-text="alias"
            show-description
            item-description="min_address"
          />
        </div>
        <div
          class="text-[#D23232] font-medium font-montserrat text-xs md:text-sm 2xl:text-base"
          v-if="!policyId"
        >
          {{ policyIdError }}
        </div>
      </div>

      <div>
        <h1 class="text-sm mb-3 title md:text-lg 2xl:text-xl">
          Confirmá tus datos de contacto
        </h1>
        <div
          class="flex flex-col md:flex-row border-section pt-4 md:pt-6 2xl:pt-9 pb-6 md:pb-8 2xl:pb-10 px-4 md:px-6 2xl:px-12 gap-x-[17px] gap-y-3"
        >
          <TextInput
            label="Teléfono"
            class="font-montserrat text-grayDetails-400 text-sm 2xl:text-base"
            name="cellphone_number"
            type="text"
          />

          <TextInput
            label="Teléfono alternativo"
            hint="(opcional)"
            class="font-montserrat text-sm 2xl:text-base"
            name="backup_cellphone_number"
            type="text"
          />

          <TextInput
            label="Email"
            class="font-montserrat text-grayDetails-400 text-sm 2xl:text-base"
            name="email"
            type="email"
          />
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped></style>
