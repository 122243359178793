import { useStorage, type RemovableRef } from "@vueuse/core";
import { defineStore } from "pinia";

type ClientStore = {
  client: RemovableRef<string | null>;
  theme: string;
  phoneNumber: string;
  whatsappNumber: string;
};

export enum ClientName {
  Galicia = "galicia",
  RioUruguaySeguros = "rus",
  Rapihogar = "rapihogar",
}

export const useClientStore = defineStore("clientStore", {
  state: (): ClientStore => ({
    client: useStorage("client", null, localStorage),
    theme: "",
    phoneNumber: "",
    whatsappNumber: "",
  }),

  getters: {
    formatPhoneNumber(state) {
      const phone = state.phoneNumber.replace(/ /g, "");
      return `tel:+54 ${phone}`;
    },
    formatWhatsappNumber(state) {
      const whatsapp = state.whatsappNumber.replace(/ /g, "");
      return `https://wa.me/54${whatsapp}`;
    },
  },
  actions: {
    setClientName() {
      if (
        import.meta.env.VITE_CLIENT_NAME === ClientName.Rapihogar ||
        import.meta.env.VITE_CLIENT_NAME === ""
      ) {
        this.client = "rapihogar";
        this.theme = "theme-rapi-hogar";
        this.phoneNumber = "0810 555 2255";
        this.whatsappNumber = "11 4857 7777";
      }
      if (import.meta.env.VITE_CLIENT_NAME === ClientName.RioUruguaySeguros) {
        this.client = "rus";
        this.theme = "theme-rus";
        this.phoneNumber = "351 5760609";
        this.whatsappNumber = "11 5996 3708";
      }
      if (import.meta.env.VITE_CLIENT_NAME === ClientName.Galicia) {
        this.client = "galicia";
        this.theme = "theme-galicia";
        this.phoneNumber = "0800 555 2272";
        this.whatsappNumber = "11 3254 8281";
      }
    },
  },
});
