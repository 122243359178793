<script setup lang="ts">
import { computed, ref } from "vue";
import { markRaw } from "vue";

import { useAuthStore } from "@/stores/authStore";

import { useClientStore } from "@/stores/clientStore";
import UserData from "./steps/UserData.vue";
import ValidateUserData from "./steps/ValidateUserData.vue";
import State from "../../views/State.vue";

const clientStore = useClientStore();
const authStore = useAuthStore();

const steps = markRaw([
  {
    component: UserData,
  },
  {
    component: ValidateUserData,
  },
]);

const currentStep = computed(() => authStore.currentStep);

const disabledLogin = computed(() => {
  return clientStore.client === "rus";
});

const sesionExpired = computed(() => {
  return JSON.parse(localStorage.getItem("sesionExpired") || "false");
});

const statusTitle = computed(() => {
  if (sesionExpired.value) {
    return 'Tu sesión expiró'
  }

  return 'Cerraste sesión'
})
</script>

<template>
  <component v-if="!disabledLogin" :is="steps[currentStep].component" />
  <State
    v-else
    logout-icon
    :status-title="statusTitle" 
    status-message="Para acceder nuevamente haz click"
  ></State>
</template>

<style scoped></style>
